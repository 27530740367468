import { Route, Redirect } from "react-router-dom";
import React, {Suspense, lazy, Fragment} from 'react';

import {
    ToastContainer,
} from 'react-toastify';


const Forms = lazy(() => import('../../DemoPages/Forms'));
// const Assignment=lazy(()=>import('../../DemoPages/Forms/AuditAssignment/AssignmentForms'))
const DigitalFiling=lazy(()=>import('../../DemoPages/Forms//DigitalFiling/DigitalDocuments'))


const AppMain = () => {

    return (
        <Fragment>

          

            {/* Forms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Please wait while we load the Form
                            </h6>
                    </div>
                </div>
            }>
                <Route path="/forms" component={Forms}/>
            </Suspense>

           
     
         
            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load Forms
                            
                        </h6>
                    </div>
                </div>
            }>
           
                <Route path="/DigitalFiling" component={DigitalFiling}/>
            </Suspense>

            <Route exact path="/" render={() => (
                // <Redirect to="/dashboards/basic"/>
                <Redirect to="/Forms/DigitalFiling"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;